<template>
  <div>
    <v-card flat>
        <v-container >
            <v-card class="pa-2" :shaped="true" >
                <v-row >
                    <v-col :md="8">
                        <v-card :shaped="true" :raised="true" height="248px">
                            <v-row >
                                <v-col :md="4">
                                    <ve-ring :data="dataRing" width="300px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" :subtitle="title" />
                                </v-col>
                                <v-col :md="6" style="margin-left: 38px; margin-top: 10px">
                                    <div v-for="(item, key) in merchantList" :key="key" style="margin-bottom: 10px" >
                                        <div>
                                            <span class="subtitle-1 font-weight-regular" style="color: #6A6A6A">{{ item.city }}</span>
                                            <span class="overline float-right font-weight-regular" style="color: #6A6A6A; position: relative; top: 10px">{{ item.persen }} %</span>
                                        </div>
                                        <v-progress-linear :value="item.persen" height="6px" rounded :color="item.color"></v-progress-linear>
                                    </div>
                                </v-col>
                                <v-col style="margin-top: 15px">
                                    <div v-for="(item, key) in merchantList" :key="key" style="margin-bottom: 15px" >
                                        <span class="subtitle-1 font-weight-black" >{{ item.stok }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card :shaped="true" :raised="true" height="248px" class="align-center">
                          <div class="ml-4 pt-4">
                            <span class="title">Most Staff on Location</span>
                          </div>
                            <v-row justify="center">
                                <v-col :md="8" class="ml-4">
                                    <div v-for="(item, key) in merchantList" :key="key" style="margin-bottom: 10px" >
                                        <div>
                                            <span class="subtitle-1 font-weight-regular" style="color: #6A6A6A">{{ item.location }}</span>
                                            <span class="caption ml-4" style="color: #D4D4D4">{{ item.city }}</span>
                                            <span class="overline float-right font-weight-regular" style="color: #6A6A6A; position: relative; top: 10px">{{ item.persen }} %</span>
                                        </div>
                                        <v-progress-linear :value="item.persen" height="6px" rounded :color="item.colorloc"></v-progress-linear>
                                    </div>
                                </v-col>
                                <v-col class="mt-2">
                                    <div v-for="(item, key) in merchantList" :key="key" class="mb-4" >
                                        <span class="subtitle-1 font-weight-black" >{{ item.stok }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-title>
                5 Locations
                <v-spacer></v-spacer>
                <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    style="width: 10px;"
                ></v-text-field>
                </v-card-title>
                <v-tabs
                    v-model="tab2"
                    align-with-title
                    background-color="transparent"
                    color="#00D79E"
                    style="margin-top: 24px">
                    <v-tabs-slider color="#00D79E"></v-tabs-slider>

                    <v-tab v-for="item in itemsTab" :key="item">
                    {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                    <v-tab-item>
                        <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                        hide-default-footer
                        class="mytable"
                        style="border-bottom: none"
                        @click:row="locDetail"
                        >
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn rounded color="#EBFCF7" style="color: #00D79E">Detail</v-btn>
                        </template>
                        </v-data-table>

                        <v-spacer></v-spacer><br>
                        <v-pagination
                        v-model="page"
                        color="#00B082"
                        :length="total_page"
                        :total-visible="7"
                        circle
                        ></v-pagination>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table
                        :headers="headers2"
                        :items="desserts2"
                        :search="search"
                        hide-default-footer
                        @click:row="clickStaff">
                        <template v-slot:header.location="{ header }">
                        <span style="color: #00B082">{{ header.text }}</span>
                        </template>
                        <template v-slot:item.staff="{ item }">
                            <v-avatar size="36" style="margin-right: 10px">
                            <img
                                src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png"
                                alt="John"
                            >
                            </v-avatar>
                            {{ item.staff }}
                        </template>
                        <template v-slot:item.permission="{ item }">
                            <div v-if="item.permission === 0">
                                <span style="color: #00D79E">High</span>
                            </div>
                            <div v-else-if="item.permission === 1">
                                <span style="color: #E84118">Low</span>
                            </div>
                            <div v-else-if="item.permission === 2">
                                <span style="color: #888888">No Access</span>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn rounded color="#EBFCF7" style="color: #00D79E">Detail</v-btn>
                        </template>
                        </v-data-table>

                        <v-spacer></v-spacer><br>
                        <v-pagination
                        v-model="page"
                        color="#00B082"
                        :length="total_page"
                        :total-visible="7"
                        circle
                        ></v-pagination>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-container>
    </v-card>
    <v-dialog v-model="dialog" max-width="664px">
      <v-card>
        <v-card-title>
          <span class="headline">Staff Detail</span>
        </v-card-title>
        <v-card-text>
            <div max-width="600px" style="padding-left: 10px">
              <v-row align="center">
                <v-col :md="2" justify-self="center">
                  <v-avatar size="88" style="margin-right: 10px">
                    <img src="@/assets/nonton film five feet apart (2019) subtitle indonesia - indoxxi.mp4[2019-06-15 17-00-43.151].png" alt="John" >
                  </v-avatar>
                </v-col>
                <v-col >
                  <v-row align="center" style="padding-left: 20px">
                    <span class="headline font-weight-bold">Budi Sumiyati</span>
                    <v-chip
                      class="ma-2"
                      style="background: transparent linear-gradient(125deg, #D500F9 0%, #FFA000 100%) 0% 0% no-repeat padding-box;"
                      label
                      text-color="white">
                      Owner
                    </v-chip>
                  </v-row>
                  <div style="padding-left: 10px">
                    <span class="subtitle-1">Staff</span>
                  </div>
                  <div>
                    <v-chip
                      class="ma-2"
                      color="#EBFCF7"
                      label
                      text-color="#00D79E">
                      0852-9382-0934
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      color="#EEF5FA"
                      label
                      text-color="#2980B9">
                      budiaye@gmail.com
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </div>
            <hr>
            <v-row>
              <v-col :md="2">
                <span class="body-1" style="color: #BEC2C4">Gender</span>
                <p class="body-1 font-weight-bold">Male</p>
              </v-col>
              <v-col :md="5">
                <span class="body-1" style="color: #BEC2C4">Employment Start Date</span>
                <p class="body-1 font-weight-bold">Sunday, 20 October 2018</p>
              </v-col>
              <v-col :md="5">
                <span class="body-1" style="color: #BEC2C4">Social Media</span>
                <p class="body-1 font-weight-bold">budisumiyabi</p>
              </v-col>
            </v-row>
            <span class="body-1" style="color: #BEC2C4">Location</span>
            <v-card v-for="item in desserts" :key="item" class="d-flex" outlined tile style="border: 1px solid #BEF4E6; border-radius: 10px; padding-left: 10px; padding-right: 12px; margin-bottom: 12px" >
              <v-row style="width: 600px" >
                <v-col class="pa-2" style="height: 40px;">
                  <span class="subtitle-1 font-weight-black" style="margin-left: 16px">{{ item.location }}</span>
                </v-col>
                <v-col class="pa-2" style="height: 40px;">
                  <span class="subtitle-1" style="color: #BEC2C4">{{ item.city }}</span>
                </v-col>
                <v-col class="text-center pa-2" style="height: 40px; background: #EBFCF7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                  <span class="subtitle-1">Hair Cutter</span>
                </v-col>
              </v-row>
            </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

// import ListActivity from './_list-activity'
// import ListTransaction from './_list-transaction'
// import ListAcqusition from './_list-acqusition'
export default {
  name: 'ReportMerchant',
  data () {
    return {
      search: '',
      dialog: false,
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Location', 'Staff'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon',
          color: '#FFFF9C',
          location: 'Kotabaru',
          colorloc: '#BDD8F1'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Jakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon',
          color: '#BEEB9F',
          location: 'Kebayoran Lama',
          colorloc: '#82A6CC'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Bali',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon',
          color: '#79BD8F',
          location: 'Ubud',
          colorloc: '#3667A6'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon',
          color: '#00A388',
          location: 'Kuta',
          colorloc: '#204177'
        }
      ],
      headers: [
        {
          text: 'Location',
          align: 'left',
          value: 'location'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Man',
          align: 'center',
          value: 'man'
        },
        {
          text: 'Woman',
          align: 'center',
          value: 'woman'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'action'
        }
      ],
      headers2: [
        {
          text: 'Staff',
          align: 'left',
          value: 'staff'
        },
        {
          text: 'Phone',
          align: 'left',
          value: 'phone'
        },
        {
          text: 'Location',
          align: 'center',
          value: 'loc'
        },
        {
          text: 'Permission',
          align: 'left',
          value: 'permission'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'action'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          location: 'Kotabaru',
          total_staff: 12,
          man: 12,
          woman: 12,
          total_class: 12
        }
      ],
      desserts2: [
        {
          staff: 'Rudi Sumiyati',
          phone: '0812-9182-8231',
          loc: '3',
          permission: 0,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Man'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 1,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Woman'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 2,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Woman'
        },
        {
          staff: 'Budi Sumiyati',
          phone: '0852-9182-8231',
          loc: '3',
          permission: 0,
          man: 12,
          woman: 12,
          total_class: 12,
          gender: 'Man'
        }
      ],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 110,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      title: {
        text: 125,
        subtext: 'Staff',
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: '80px'
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    clickStaff () {
      this.dialog = true
    },

    locDetail () {
      this.$router.push('/report/report_merchant/location/2')
    }
  }
}
</script>

<style lang="scss">
.table-header {
  thead {
    border: 1px solid #BEF4E6;
    border-radius: 10px;
  }
}
</style>
