<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="grey lighten-5 fill-height">
                <v-row
                v-for="n in 1"
                :key="n"
                :class="n === 1 ? 'mb-6' : ''"
                no-gutters>
                <v-col :md="4" style="margin-right: 16px">
                    <div class="px-3" style="padding-top: 24px; padding-bottom: 5px;">
                        <ve-ring :data="dataRing" height="220px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" />
                        <div class="pl-2" style="border: 1px solid #BEF4E6; border-radius: 10px;">
                          <span class="caption mr-4" style="color: #BEC2C4">Total Revenue</span>
                          <span class="title font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                        </div>
                    </div>
                </v-col>
                <v-col style="margin-right: 16px">
                    <v-card class="fill-height" width="300px" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                        <v-row class="mb-6 align-center" no-gutters>
                            <v-col :md="4" class="text-center d-flex-align-center">
                                <v-btn class="ma-2" fab large color="#EBFCF7">
                                    <v-icon color="#00B082" :large="true">mdi-hand-left</v-icon>
                                    <v-icon color="#00B082" :large="true">mdi-hand-right</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col :md="3">
                                <span class="headline font-weight-bold">580</span><br>
                                <span class="body-1 font-regular" style="color: #00B082">Product</span>
                            </v-col>
                        </v-row>
                        <v-row class="align-center" no-gutters style="padding-left: 20px; height: 65px">
                            <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                                <div class="title text-left" style="color: #00D79E">766</div>
                                <div class="caption" style="color: #00D79E; opacity: 0.48; position: relative; top: -35px; right: -40px; width: 50px;">Service Booked</div>
                            </v-col>
                            <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                                <div class="title text-left" style="color: #E74C3C">766</div>
                                <div class="caption" style="color: #E74C3C; opacity: 0.48; position: relative; top: -35px; right: -40px; width: 50px;">Service Booked</div>
                            </v-col>
                        </v-row>
                        <div style="padding-left: 16px; margin-bottom: 16px">
                          <div class="subtitle-1" style="color: #BEC2C4">Revenue</div>
                          <span class="headline font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                        </div>
                    </v-card>
                </v-col>
                <v-col style="margin-right: 16px">
                    <v-card class="fill-height" width="300px" :shaped="true" :raised="true" style="padding-top: 24px; padding-bottom: 5px;">
                        <v-row class="mb-6 align-center" no-gutters>
                            <v-col :md="4" class="text-center d-flex-align-center">
                                <!-- <v-btn class="ma-2" fab large color="#EBFCF7">
                                    <v-icon color="#00B082" :large="true">$vuetify.icons.staff</v-icon>
                                </v-btn> -->
                                <div class="pa-3 ml-4" style="background: #EBFCF7; width: 68px; height:68px; border-radius: 50%;">
                                  <v-icon color="#00B082" :large="true">$vuetify.icons.staff</v-icon>
                                </div>
                            </v-col>
                            <v-col :md="3">
                                <span class="headline font-weight-bold">580</span><br>
                                <span class="body-1 font-regular" style="color: #00B082">Classes</span>
                            </v-col>
                        </v-row>
                        <v-row class="align-center" no-gutters style="padding-left: 20px; height: 65px">
                            <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                                <div class="title text-left" style="color: #00D79E">766</div>
                                <div class="caption" style="color: #00D79E; opacity: 0.48; position: relative; top: -35px; right: -40px; width: 50px;">Service Booked</div>
                            </v-col>
                            <v-col :md="5" class="ma-2 text-center d-flex-align-center">
                                <div class="title text-left" style="color: #E74C3C">766</div>
                                <div class="caption" style="color: #E74C3C; opacity: 0.48; position: relative; top: -35px; right: -40px; width: 50px;">Service Booked</div>
                            </v-col>
                        </v-row>
                        <div style="padding-left: 16px; margin-bottom: 16px">
                          <div class="subtitle-1" style="color: #BEC2C4">Revenue</div>
                          <span class="headline font-weight-bold" style="color: #4CD137">Rp. 150.000.000,00</span>
                        </div>
                    </v-card>
                </v-col>
                </v-row>
                <v-card class="pa-2" :shaped="true" :raised="true" width="100%">
                <v-card-title style="height: 64px">
                    <v-row class="mb-6" no-gutters>
                    <v-col :md="6">
                        Merchant Transaction
                    </v-col>
                    <v-col :md="4">
                        <div class="float-right" style="margin-right: 8px">
                        <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                            :value="computedDateFormattedMomentjs"
                            readonly
                            v-on="on"
                            solo
                            background-color="#F5F5F5"
                            style="width: 200px"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            @click:date="setDate(0)"
                            range
                        ></v-date-picker>
                        </v-menu>
                        </div>
                    </v-col>
                    <v-col :md="2">
                        <v-autocomplete
                        v-model="selectCity"
                        :items="dropdown_font"
                        solo
                        label="All City"
                        background-color="#F5F5F5"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                </v-card-title>
                <v-tabs
                  v-model="tab2"
                  align-with-title
                  background-color="transparent">
                  <v-tabs-slider color="yellow"></v-tabs-slider>

                  <v-tab v-for="item in itemsTab" :key="item">
                  {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                  <v-tab-item>
                    <ve-line :data="dataLine" :legend-visible="true" :colors="colorsLine" :settings="lineSettings" :extend="extend" :xAxis="xaxis" :after-config="afterConfig" />
                  </v-tab-item>
                  <v-tab-item>
                    <ve-line :data="dataLine2" :legend-visible="true" :colors="'#E74C3C'" :settings="lineSettings" :extend="extend" :xAxis="xaxis" />
                  </v-tab-item>
                </v-tabs-items>
                <v-card class="pa-2" :shaped="true" >
                    <v-card-title>
                      12456 Transaction
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                      ></v-text-field>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                      <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :search="search"
                      hide-default-footer
                      class="mytable"
                      style="border-bottom: none"
                      @click:row="rowClick"
                    >
                    <template v-slot:header.service_name="{ header }">
                      <span style="color: #00B082">{{ header.text }}</span>
                    </template>
                    <template v-slot:item.revenue="{ item }">
                      <span style="color: #4CD137">{{ item.revenue}}</span>
                    </template>
                    <template v-slot:item.cancelled="{ item }">
                      <span style="color: #E84118">{{ item.cancelled}}</span>
                    </template>
                    </v-data-table>

                    <v-spacer></v-spacer><br>
                    <v-pagination
                      v-model="page"
                      color="#00B082"
                      :length="total_page"
                      :total-visible="7"
                      circle
                      ></v-pagination>
                  </v-card>
                </v-card>
            </v-container>
            </div>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

export default {
  name: 'ListActivity',
  props: ['extend', 'page', 'total_page'],

  data () {
    return {
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant'
        }
      ],
      headers: [
        {
          text: 'Service',
          align: 'left',
          value: 'service_name'
        },
        {
          text: 'Price',
          align: 'left',
          value: 'price'
        },
        {
          text: 'Total Booked',
          align: 'center',
          value: 'total_booked'
        },
        {
          text: 'Cancelled',
          align: 'center',
          value: 'cancelled'
        },
        {
          text: 'Revenue',
          align: 'right',
          value: 'revenue'
        }
      ],
      desserts: [
        {
          company_name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13.00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 2,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_booked: 12,
          platform: 'POS, Online, Marketplace',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 3,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        },
        {
          company_name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_booked: 12,
          platform: 'POS',
          joined_time: '13:00',
          date: '21 Agustus 2019 13:00',
          type: 1,
          service_name: 'Haircut - Short',
          revenue: '1.320.000',
          cancelled: '2',
          price: 15.000
        }
      ],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      tab2: null,
      itemsTab: [
        'Service', 'Product', 'Class'
      ],
      dataLine: {
        columns: ['date', 'appointment', 'cancelled'],
        rows: [{ 'date': '10', 'appointment': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'appointment': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'appointment': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'appointment': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      dataLine2: {
        columns: ['date', 'product_sold'],
        rows: [{ 'date': '10', 'product_sold': 10, 'cancelled': 5, 'classes': 2 },
          { 'date': '11', 'product_sold': 20, 'cancelled': 20, 'classes': 6 },
          { 'date': '12', 'product_sold': 17, 'cancelled': 16, 'classes': 5 },
          { 'date': '13', 'product_sold': 28, 'cancelled': 24, 'classes': 10 }
        ]
      },
      colorsLine: ['#00D79E', '#FFB677', '#C7A8FF'],
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#E74C3C'
          }, {
            offset: 1,
            color: '#FFFFFF'
          }])
        }
      },
      xaxis: {
        type: 'category',
        boundaryGap: false
      },
      search: '',
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 }
        ]
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 110,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      colorsRing: ['#7CFDBE', '#00B082', '#A1FFD1', '#C5DBFF'],
      title: {
        text: 125,
        subtext: 'services and \n classes',
        textStyle: {
          color: '#333333',
          fontSize: '36',
          fontStyle: 'normal'
        },
        x: 'center',
        y: '80px'
      }
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },
    afterConfig (options) {
      options.series[0].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      }
      options.series[1].areaStyle = {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#FFB677'
          }, {
            offset: 1,
            color: '#ffd0a8'
          }])
        }
      }
      return options
    },
    rowClick (row) {
      this.$router.push('/report/report_merchant/sales/2')
    }
  }
}
</script>
