<template>
  <div>
    <v-card flat>
        <v-container >
            <v-card class="pa-2" :shaped="true" >
                <v-card-title>
                5 Locations
                <v-spacer></v-spacer>
                <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>Download</v-btn>
                <v-btn rounded outlined style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>Print</v-btn>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    style="width: 10px;"
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
                hide-default-footer
                class="mytable"
                style="border-bottom: none"
                >
                <template v-slot:header.location="{ header }">
                <span style="color: #00B082">{{ header.text }}</span>
                </template>
                <template v-slot:item.location="{ item }">
                {{ item.location }}
                </template>
                </v-data-table>

                <v-spacer></v-spacer><br>
                <v-pagination
                v-model="page"
                color="#00B082"
                :length="total_page"
                :total-visible="7"
                circle
                ></v-pagination>
            </v-card>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

// import ListActivity from './_list-activity'
// import ListTransaction from './_list-transaction'
// import ListAcqusition from './_list-acqusition'
export default {
  name: 'ReportMerchant',
  data () {
    return {
      search: '',
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Profile', 'Location', 'Staff', 'Sales'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        }
      ],
      headers: [
        {
          text: 'Location',
          align: 'left',
          value: 'location'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Total Service',
          align: 'center',
          value: 'total_service'
        },
        {
          text: 'Total Product',
          align: 'center',
          value: 'total_product'
        },
        {
          text: 'Total Class',
          align: 'center',
          value: 'total_class'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          total_service: 12,
          total_product: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          total_service: 12,
          total_product: 12,
          total_class: 12
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          location: 'Kotabaru',
          total_staff: 12,
          total_service: 12,
          total_product: 12,
          total_class: 12
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          location: 'Kotabaru',
          total_staff: 12,
          total_service: 12,
          total_product: 12,
          total_class: 12
        }
      ],
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 200,
        radius: [60, 100],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
