<template>
  <div>
    <v-card flat>
        <v-container class="grey lighten-5 fill-height">
            <v-card :shaped="true" :raised="true" width="100%" style="margin-bottom: 25px">
                <!-- <v-card :shaped="true" > -->
                <div class="align-center" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; margin-bottom: 16px; width: 100%; height: 80px;">
                    <v-btn class="ma-2" fab color="#F4F4F4" style="margin-left: 32px">
                        <v-icon color="#000000" :x-large="true">mdi-store</v-icon>
                    </v-btn>
                    <span class="title" style="color: #00B082; margin-left: 16px">Djemari Salon and Spa</span>
                    <div class="float-right align-center" style="margin-top: 20px; margin-right: 24px">
                        <v-chip class="mr-2" color="#EBFCF7" text-color="#00D79E">POS</v-chip>
                        <v-chip class="mr-2" color="#EBFCF7" text-color="#00D79E">Online</v-chip>
                        <v-chip color="#EBFCF7" text-color="#00D79E">Marketplace</v-chip>
                    </div>
                </div>
                <!-- </v-card> -->
            <v-tabs
                v-model="tab2"
                align-with-title
                background-color="transparent"
                color="#00D79E"
                style="margin-top: 24px">
                <v-tabs-slider color="#00D79E"></v-tabs-slider>

                <v-tab v-for="item in itemsTab" :key="item">
                {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab2">
                <v-tab-item>
                    <v-row style="margin-top: 16px; margin-left: 32px">
                        <v-col :md="4">
                            <v-img
                                height="266"
                                width="360"
                                src="@/assets/BRERC_SPA_Cabin_1A.png"
                            ></v-img>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <span class="subtitle-1 font-weight-bold">ID</span>
                                    <v-spacer></v-spacer>
                                    <span class="subtitle-1" style="color: #6A6A6A">AF918293F</span>
                                    <br><br>
                                    <span class="subtitle-1 font-weight-bold">POS Devices</span>
                                    <v-spacer></v-spacer>
                                    <span class="subtitle-1" style="color: #6A6A6A">12</span>
                                </v-col>
                                <v-col>
                                    <span class="subtitle-1 font-weight-bold">Contact</span>
                                    <v-spacer></v-spacer>
                                    <span class="subtitle-1" style="color: #6A6A6A">0862-9283-1234</span>
                                    <br><br>
                                    <span class="subtitle-1 font-weight-bold">Expiry Date</span>
                                    <v-spacer></v-spacer>
                                    <span class="subtitle-1" style="color: #E84118">12 Apr 2020</span>
                                </v-col>
                            </v-row>
                            <span class="subtitle-1 font-weight-bold">Main Address</span>
                            <v-spacer></v-spacer>
                            <span class="subtitle-1" style="color: #6A6A6A">
                                Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281
                            </span>
                        </v-col>
                    </v-row>
                <p class="font-weight-bold title" style="margin-left: 16px; margin-top: 40px"> Business Category </p>
                <v-row align="center">
                    <v-col :md="4">
                        <ve-ring :data="dataRing" width="300px" height="300px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" :title="title" />
                    </v-col>
                    <v-col>
                        <div v-for="(item, key) in merchantList" :key="key" style="min-height: 40px; margin-bottom: 8px; margin-right: 24px">
                            <div style="margin-bottom: 5px">
                                <span class="title font-weight-regular" style="color: #6A6A6A">{{ item.service }}</span>
                                <span class="title float-right font-weight-regular" style="color: #6A6A6A">{{ item.persen }} %</span>
                            </div>
                            <v-progress-linear :value="item.persen" height="6px" rounded color="#FFE78F"></v-progress-linear>
                        </div>
                    </v-col>
                    <v-col>
                        <div v-for="(item, key) in merchantList" :key="key" style="min-height: 40px; margin-bottom: 8px; margin-right: 24px">
                            <div style="margin-bottom: 5px">
                                <span class="title font-weight-regular" style="color: #6A6A6A">{{ item.service }}</span>
                                <span class="title float-right font-weight-regular" style="color: #6A6A6A">{{ item.persen }} %</span>
                            </div>
                            <v-progress-linear :value="item.persen" height="6px" rounded color="#FFE78F"></v-progress-linear>
                        </div>
                    </v-col>
                </v-row>
                </v-tab-item>
                <v-tab-item>
                    <location-detail></location-detail>
                </v-tab-item>
                <v-tab-item>
                    <location-staff></location-staff>
                </v-tab-item>
                <v-tab-item>
                    <location-sales></location-sales>
                </v-tab-item>
            </v-tabs-items>
            </v-card>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'

import LocationDetail from './_location-detail'
import LocationStaff from './_location-staff'
import LocationSales from './_location-sales'
export default {
  name: 'ReportMerchant',
  components: {
    LocationDetail,
    LocationStaff,
    LocationSales
  },
  data () {
    return {
      search: '',
      tab: null,
      tab2: null,
      address: 'Jalan Akasia, Jl. Seturan Raya No.1A, Kledokan, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281',
      items: [
        'Merchant List', 'Activity', 'Transaction', 'Acquisition'
      ],
      itemsTab: [
        'Profile', 'Location', 'Staff', 'Sales'
      ],
      alignments: [
        'center'
      ],
      merchantList: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          persen: '32',
          stok: 322,
          status: 'Merchant',
          service: 'Salon'
        }
      ],
      headers: [
        {
          text: 'Merchant Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Total Staff',
          align: 'center',
          value: 'total_staff'
        },
        {
          text: 'Platform',
          align: 'left',
          value: 'platform'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Time Joined',
          align: 'right',
          value: 'time_joined'
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          total_staff: 12,
          platform: 'POS, Online, Marketplace',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          total_staff: 12,
          platform: 'POS',
          time_joined: '13 Apr 2019 13:00',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ],
      title: {
        text: 12,
        textStyle: {
          color: '#333333',
          fontSize: '69',
          fontStyle: 'normal'
        },
        x: 'center',
        y: 'center'
      },
      ringSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        offsetY: 150,
        radius: [90, 130],
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        }
      },
      lineSettings: {
        area: true,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsRing: ['#FFCB9F', '#FFE78F', '#A1FFD1', '#C5DBFF'],
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataRing: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10', 'sales': 20 },
          { 'date': '11', 'sales': 45 },
          { 'date': '12', 'sales': 30 },
          { 'date': '13', 'sales': 40 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      page: 1,
      total_page: 10
    }
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    }
  },

  methods: {
    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
